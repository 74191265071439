import Link from 'next/link';

export default function Custom404() {
  return (
    <>
      <h1>404 - Seite existiert nicht</h1>
      <Link href='/'>Zurück zur Startseite</Link>
    </>
  );
}
